import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Box,
  List,
  ListItem,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getCookie } from 'cookies-next';
import useSession from '@/utils/hooks/useSession';
import usePost from '@/utils/hooks/api/vercel/usePost';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';

export default function EulaAgreement() {
  const session = useSession() || {};
  const user = session.user;
  const router = useRouter();

  const impUser = JSON.parse(getCookie('impersonatedUser') || '{}');
  const { fire, fireAsync, isLoading: loading } = usePost('/users/eula');

  const [stayOpen, setStayOpen] = useState(
    !impUser?.email && user && !user?.acceptedEula
  );

  if (!user) return <></>;
  return (
    <Dialog open={stayOpen}>
      <DialogTitle>
        <Typography variant='h4' component='h1'>
          Welcome to the Cornerstone Hub!
        </Typography>
        <Typography variant='subtitle1'>
          To get started, please review and accept the license agreement.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ border: '1px solid black', p: 1 }}
          component='div'
        >
          <Typography variant='h6' component='h2' color='black'>
            End User License Agreement
          </Typography>
          <Typography variant='caption'>Version: September 2023</Typography>
          <Typography color='black'>
            Before registering or using the Cornerstone Information Systems Inc.
            (“Cornerstone”) Hub software and related software services
            (“Cornerstone Hub”), you, the Licensee (“Customer”), must read and
            agree to this End User License Agreement and Cornerstone’s Terms of
            Use and Privacy Statement, as amended from time to time by
            Cornerstone (this End User License Agreement, the Terms of Use and
            Privacy Statement are collectively defined herein as the
            "Agreements"). The Cornerstone Hub is the copyrighted property of
            Cornerstone and/or its suppliers. Use of the Cornerstone Hub is
            governed by the terms of the Agreements. Internet URL links for the
            Terms of Use and Privacy Statement are as follows:
          </Typography>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item', ml: 4 }}>
              <Typography>
                <Link href='/terms-of-use' target='_blank'>
                  Cornerstone “Terms of Use”
                </Link>
              </Typography>
            </ListItem>
            <ListItem sx={{ display: 'list-item', ml: 4 }}>
              <Typography>
                <Link href='/privacy-policy' target='_blank'>
                  Cornerstone “Privacy Statement”
                </Link>
              </Typography>
            </ListItem>
          </List>
          <Typography sx={{ pb: 1 }} color='black'>
            Do not use the Cornerstone Hub if you are unwilling to accept all
            terms and conditions of the Agreements. Any use of the Cornerstone
            Hub is subject to all terms and conditions of the Agreements.
          </Typography>
          <Typography color='black'>
            The Cornerstone Hub is made available solely for use by end users
            subject to the Agreements. Any reproduction or redistribution of the
            Cornerstone Hub not in accordance with the Agreements is expressly
            prohibited.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <Typography sx={{ alignSelf: 'center' }} color='error'>
        ** You must accept the Cornerstone Hub license agreement to access this
        site. **
      </Typography>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-around', pb: 2, pt: 1 }}
      >
        <LoadingButton
          variant='contained'
          loading={loading}
          color='error'
          onClick={async () => {
            fire({ answer: 'Decline' });
            await Auth.signOut();
            router.push(`/login?callbackUrl=${router.asPath}`);
          }}
        >
          Decline
        </LoadingButton>
        <LoadingButton
          variant='contained'
          loading={loading}
          color='success'
          onClick={async () => {
            await fireAsync({ answer: 'Accept' });
            setStayOpen(false);
          }}
        >
          Accept
        </LoadingButton>
      </Box>
    </Dialog>
  );
}
