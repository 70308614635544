import React, { useState, useRef } from 'react';
import { useMediaQuery, Box } from '@mui/material';
import { IconButton } from '@mui/material';
import { useTheme } from '@emotion/react';
import SidebarBase from '../Sidebar/SidebarBase';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import MobileSidebar from '@/components/LayoutTools/Sidebar/MobileSidebar';

export default function Sidebar({ toggleDrawer, mobileMenuOpen }) {
  const sidebarRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const expandedWidth = 300;
  // 16 padding, 24 icon, 16 padding
  const collapsedWidth = 56;
  const [sidebarWidth, setSidebarWidth] = useState(expandedWidth);

  const toggleResize = () => {
    if (sidebarWidth === expandedWidth) {
      setSidebarWidth(collapsedWidth);
    } else {
      setSidebarWidth(expandedWidth);
    }
  };

  if (isMobile) {
    return (
      <MobileSidebar
        toggleDrawer={toggleDrawer}
        mobileMenuOpen={mobileMenuOpen}
        isMobile={isMobile}
      />
    );
  }
  return (
    <Box
      ref={sidebarRef}
      style={{
        width: sidebarWidth,
        flexGrow: 0,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'row',
        zIndex: 2,
      }}
    >
      <SidebarBase
        isMobile={isMobile}
        sidebarWidth={sidebarWidth}
        toggleResize={toggleResize}
      />
      <Box>
        {sidebarWidth < 80 ? (
          <IconButton
            onClick={() => setSidebarWidth(expandedWidth)}
            aria-label='grow'
          >
            <ArrowCircleRightOutlinedIcon />
          </IconButton>
        ) : (
          <>
            <IconButton
              onClick={() => setSidebarWidth(collapsedWidth)}
              aria-label='shrink'
            >
              <ArrowCircleLeftOutlinedIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
}
