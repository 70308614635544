export const CIS_INTERNAL = 'CIS_INTERNAL';
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const RISK_MESSENGER = 'riskMessenger';
export const IBANK = 'iBank';

export const ROLE_OPTIONS = [
  {
    label: 'User',
    value: USER,
  },
  {
    label: 'Admin',
    value: ADMIN,
  },
  {
    label: 'Cornerstone Internal',
    value: CIS_INTERNAL,
  },
];

export const ADMIN_ROLE_OPTIONS = [
  {
    label: 'User',
    value: USER,
  },
  {
    label: 'Admin',
    value: ADMIN,
  },
];

export const IS_PROD = ['production', 'prodstage'].includes(
  process.env.ENVIRONMENT
);
export const IS_DEV = ['development', 'stage'].includes(
  process.env.ENVIRONMENT
);

if (!IS_PROD && !IS_DEV) {
  throw new Error(
    'Invalid .env.ENVIRONMENT. Must be of values: development, stage, production, prodstage'
  );
}
export const AWS_REGION = IS_PROD ? 'us-east-1' : 'us-east-2';

// I removed the env variable. For local development, please change the value here.
// If you are reading a code review and the line below is changed, please ensure it was intentional.
export const IBANK_URL = IS_PROD
  ? 'https://apps.ciswired.com'
  : 'https://apps.dev.ciswired.com';

export const STANDARD_AUTH_JWKS_URL = IS_PROD
  ? 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_GDcHEV6qU/.well-known/jwks.json'
  : 'https://cognito-idp.us-east-2.amazonaws.com/us-east-2_4YQ99GNA1/.well-known/jwks.json';

export const NEXT_PUBLIC_ORG_LOGOS_S3_BUCKET = IS_PROD
  ? 'https://ciswired-org-logos.s3.us-east-1.amazonaws.com/'
  : 'https://cis-org-logos.s3.us-east-2.amazonaws.com/';

export const EDA_TABLE_NAME = 'EDA.V3';
export const ACTIVITY_LOG_TABLE_NAME = 'ActivityLog.V1';
export const SIGNIFICANT_DATES_TABLE_NAME = 'SignificantDates.V1';
export const ORG_INDEX_NAME = 'OrganizationsUserIndex';
