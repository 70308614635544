import Joi from 'joi';
import isEqual from 'lodash/isEqual';

// todo: we should dynamically generate this from reportMap for Sigma embeds
export const arbiterConfig = {
  reportingets: {
    active: false,
    airReports: {
      advanceAir: false,
      co2Reporting: false,
      unusedTickets: false,
    },
    authorizationReports: {
      authorizationByStatus: false,
      adHocReport: false,
    },
    hotelReports: {
      hotelAttachmentSignificance: false,
      hotelSegmentation: false,
    },
    dataInvestigation: false,
    expenseReports: false,
  },
  reporting: {
    active: false,
    recordProcessing: false,
    errors: false,
    transactionCounts: false,
    scheduleProcessing: false,
    fareChecker: false,
  },
  iBank: {
    active: false,
    broadcastCalendar: false,
    iBankLogin: false,
    agencies: [],
    users: [],
  },
  iQCX: {
    active: false,
  },
  travelAuthorization: {
    active: false,
    activeRequests: false,
    searchRequests: false,
    gtrLog: false,
    meetings: false,
    travelPolicy: false,
  },
  workflow: {
    active: false,
    dashboard: false,
    overview: false,
    schedules: false,
    tables: false,
    engines: false,
    libraries: false,
    logs: false,
    tableConnector: false,
    queueMonitor: false,
    ticketTrak: false,
    resrules: false,
  },
  eda: {
    active: false,
  },
  dataInsights: {
    active: false,
    iqcxBookedTravel: false,
    programManagement: {
      advanceAirPurchase: false,
      authorizations: false,
      hotelAttachment: false,
      hotelSegmentation: false,
      sustainability: false,
      unusedTickets: false,
    },
    expenseData: false,
    myReports: false,
    scheduleChanges: false,
  },
  cisInternal: {
    active: false,
    engineMonitor: false,
    scheduleMover: false,
    queueMonitor: false,
    iQCXAlertNotifications: false,
  },
  demoNavigation: false,
};

function _getJoiItem(value) {
  if (value instanceof Array) {
    return Joi.array().default(value).failover(value);
  } else if (value instanceof Object) {
    return _getJoiObject(value);
  } else {
    return Joi.boolean().default(value).failover(value);
  }
}

function _getJoiObject(featureObject) {
  const joiObject = {};
  for (let key of Object.keys(featureObject)) {
    joiObject[key] = _getJoiItem(featureObject[key]);
  }
  return Joi.object(joiObject)
    .default(featureObject)
    .failover(featureObject)
    .options({ stripUnknown: true });
}

function _getJoiConfig(config) {
  const joiObject = {};
  for (let key of Object.keys(config)) {
    joiObject[key] = _getJoiItem(config[key]);
  }
  return Joi.object(joiObject).default(config);
}

// Given a config, ensure that it conforms to our expected structure.
export function validateAndFixPermissionsConfig(
  initialConfig,
  defaultConfig = arbiterConfig
) {
  // This allows callers to use logic to only sometimes pass this.
  if (!defaultConfig) defaultConfig = arbiterConfig;

  const schema = _getJoiConfig(defaultConfig);
  let newConfig;
  try {
    newConfig = Joi.attempt(initialConfig, schema);
  } catch (e) {
    console.error('Error validating permissions config:', e);
    return { config: defaultConfig, changed: false };
  }
  return { config: newConfig, changed: !isEqual(initialConfig, newConfig) };
}
